<template>
  <div
    @click="shortenText"
    class="owl-item active"
    style="width: 540px; margin-right: 30px"
  >
    <div class="single-testimonial">
      <div class="img-wrapper">
        <img v-bind:src="testimonial.image" alt />
      </div>
      <div class="client-desc">
        <p v-if="expanded" class="comment">
          {{ testimonial.comment }}
          <button
            class="testimonial-see-btn"
            v-if="tooLong"
            @click="shortenText"
          >
            See Less
          </button>
        </p>
        <p v-if="!expanded" class="comment">
          {{ comment }}
          <button
            class="testimonial-see-btn"
            v-if="tooLong"
            @click="shortenText"
          >
            See More
          </button>
        </p>
        <h6 class="name">{{ testimonial.name }}</h6>
        <p class="rank">{{ testimonial.designation }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    testimonial: {}
  },
  data() {
    return {
      expanded: false,
      wordsLength: 19
    };
  },
  computed: {
    comment: function() {
      let str = this.testimonial.comment;
      let words = str.split(/\s+/);
      if (!this.expanded && words.length <= this.wordsLength) {
        return str;
      } else {
        return words.slice(0, 10).join(" ") + "...";
      }
    },
    tooLong: function() {
      return this.testimonial.comment.split(/\s+/).length > this.wordsLength;
    }
  },
  methods: {
    shortenText: function() {
      console.log(this.expanded);
      this.expanded = !this.expanded;
    }
  }
};
</script>
