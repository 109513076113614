<template>
  <!--   Testimonial section start    -->
  <div class="testimonial-section pb-115">
    <div class="container">
      <div class="row text-center">
        <div class="col-lg-6 offset-lg-3">
          <h2 class="section-summary">
            Testimonials
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            class="testimonial-carousel owl-carousel owl-theme owl-loaded owl-drag"
          >
            <div class="owl-stage-outer">
              <div
                class="owl-stage"
                style="
                    transform: translate3d(0px, 0px, 0px);
                    transition: all 0s ease 0s;
                    width: 1710px;
                  "
              >
                <Testimonial
                  v-for="testimonial in testimonials"
                  v-bind:key="testimonial.designation"
                  v-bind:testimonial="testimonial"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--   Testimonial section end    -->
</template>

<script>
import Testimonial from "@/components/Testimonial.vue";

export default {
  data() {
    return {
      testimonials: [
        {
          image: "https://i.imgur.com/vLEjbKJ.jpg",
          comment:
            "Mechazy is disrupting engineering by helping to create innovative technologies that make our everyday lives more easy and convenient. We have had the opportunity to work with Dulaj in several projects and we are still impressed by his expertise and great knowledge in mechanical and architectural design, which helped us reach major milestones and achieve our dream of making healthcare more accessibleto the underserved urban and rural populations. With absolutelyno reserve, we are happy to endorse Mechazyas a Premium designer. Go Mechazy!",
          name: "Giscard R. Minlend",
          designation: "Chairman & CEO | ED GLOBAL TELEHEALTH LLC"
        },
        {
          image: "https://i.imgur.com/M4uYbKC.jpg",
          comment:
            "They provide innovative solutions with the best. tempor incididunt utla bore et dolor tempor incididunt.",
          name: "Jonathon doe",
          designation: "Director, art media"
        },
        {
          image: "https://i.imgur.com/CBnPVRK.jpg",
          comment:
            "They provide innovative solutions with the best. tempor incididunt utla bore et dolor tempor incididunt.",
          name: "Ben Stokes",
          designation: "CTO, Mastermind"
        }
      ]
    };
  },
  components: {
    Testimonial
  }
};
</script>
