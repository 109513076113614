<template>
  <Header />
  <!--   breadcrumb area start   -->
  <div
    v-if="service"
    class="breadcrumb-area service-details"
    style="
      background-image: url(&#39;https://i.imgur.com/AtERUas.jpg&#39;);
      background-size: cover;
    "
  >
    <div class="container">
      <div class="breadcrumb-txt service-details">
        <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-10">
            <span>Service Details</span>
            <h1>{{ service.title }}</h1>
            <ul class="breadcumb">
              <li>
                <a href="https://mechazy.com/">Home</a>
              </li>
              <li>Service Details</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="breadcrumb-area-overlay"
      style="background-color: #0a3041; opacity: 0.8"
    ></div>
  </div>
  <!--   breadcrumb area end    -->

  <!--    services details section start   -->
  <div v-if="service" class="service-details-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-11">
          <div class="service-details">
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />

            <!-- Modal -->
            <div
              class="modal fade"
              id="imagemodal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal">
                      <span aria-hidden="true">×</span>
                      <span class="sr-only">Close</span>
                    </button>
                    <img
                      src="https://mechazy.com/service/Mechanical-Design/1"
                      class="imagepreview"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal -->

            <div class="blue-box-title">
              <h3 style="color: #224c62">{{ service.title }}</h3>
            </div>

            <div style="color: black">
              <p v-for="description in service.descriptions" v-bind:key="description.length" class="thani-nil-kotuwa">{{ description }}</p>
            </div>

            <div class="images-hari-lakunu" style="color: black">
              <span v-bind:key="subtitle" v-for="subtitle in service.subTitles">
                <br />&nbsp; &nbsp; &nbsp;
                <img
                  src="https://i.imgur.com/Jwf75gQ.png"
                  alt="Smiley face"
                  height="18"
                  width="18"
                />
                &nbsp; {{ subtitle }}
                <br />
              </span>
            </div>

            <br />
            <div
              class="blue-box2 blue-box-title"
              style="background-color: #d4ffff; color: #224c62"
            >
              For pricing, Please check our recent projects and apply for a free
              quotation
            </div>
            <br />

            <!-- PROJECTS CONTAINER -->
            <div v-if="projects && projects.length > 0" class="trapezoid">
              <span class="title-2">RECENT PROJECTS</span>
            </div>
            <div class="row recent-projects-container">
              <div
                v-bind:key="project.id"
                v-for="project in projects"
                class="col-sm-12 col-md-6 col-lg-4 mb-4"
              >
                <div class="card h-100 single-team-member">
                  <div
                    :id="`carousel-${project.id}`"
                    class="carousel slide card-img-top"
                    data-ride="carousel"
                    data-interval="false"
                    style="z-index: 11"
                  >
                    <div class="carousel-inner">
                      <div
                        v-bind:key="image"
                        v-for="(image, idx) in project.images"
                        class="carousel-item"
                        :class="{ active: idx == 0 }"
                      >
                        <img
                          v-bind:src="image"
                          alt
                          class="card-img-top"
                          style="padding: 1rem;"
                        />
                      </div>
                      <a
                        class="carousel-control-prev"
                        :href="`#carousel-${project.id}`"
                        role="button"
                        data-slide="prev"
                      >
                        <i
                          class="fas fa-arrow-left"
                          aria-hidden="true"
                          style="color: #0b2738"
                        ></i>
                        <span class="sr-only">
                          Previous
                        </span>
                      </a>
                      <a
                        class="carousel-control-next"
                        :href="`#carousel-${project.id}`"
                        role="button"
                        data-slide="next"
                      >
                        <i
                          class="fas fa-arrow-right"
                          aria-hidden="true"
                          style="color: #0b2738"
                        ></i>
                        <span class="sr-only" style="color: #0b2738">
                          Next
                        </span>
                      </a>
                    </div>
                  </div>
                  <div class="card-body" style="z-index: 11">
                    <h4 class="member-name" style="color: #0b2738">
                      <strong>{{ project.title }}</strong>
                    </h4>
                    <p class="card-text" style="color: #224c62">
                      {{ project.clientCountry }}
                    </p>
                    <div class="d-flex justify-content-end">
                      <a
                        type="button"
                        class="btn btn-dark single-team-member"
                        v-bind:href="`/project?project=${project.id}`"                        
                        style="background-color: #0b2838; z-index: 11"
                      >
                        <i class="fas fa-caret-right"></i>
                        View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- PROJECTS CONTAINER -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--    services details section end   -->
  <Footer />
</template>

<script>
import firebase from "../util/firebase";
import serviceMap from "../util/services";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

const urlParams = new URLSearchParams(window.location.search);
const service = urlParams.get("service");

export default {
  data() {
    return {
      service: serviceMap[service],
      projects: []
    };
  },
  mounted() {
    var self = this;
    firebase
      .firestore()
      .collection("project")
      .where("services", "array-contains", service)
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(doc => {
          self.projects.push({ id: doc.id, ...doc.data() });
        });
      })
      .catch(function(error) {
        console.log("Error getting documents:", error);
      });
  },
  components: {
    Header,
    Footer
  }
};
</script>

<style scoped>
@import "/css/service-custom.css";
</style>
