<template>
  <Header />
  <!--   breadcrumb area start   -->
  <div
    class="breadcrumb-area cases"
    style="
      background-image: url(&#39;https://i.imgur.com/AtERUas.jpg&#39;);
      background-size: cover;
    "
  >
    <div class="container">
      <div class="breadcrumb-txt">
        <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-10">
            <span>Request A Quote</span>
            <h1>Have a project ? Send us a quote</h1>
            <ul class="breadcumb">
              <li>
                <a href="https://mechazy.com/">Home</a>
              </li>
              <li>Quote Page</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="breadcrumb-area-overlay"
      style="background-color: #0a3041; opacity: 0.8"
    ></div>
  </div>
  <!--   breadcrumb area end    -->

  <!--   quote area start   -->
  <div class="quote-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <form @submit.prevent="processForm">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-element mb-4">
                  <label>
                    Name
                    <span>**</span>
                  </label>
                  <input
                    v-model="name"
                    name="name"
                    type="text"
                    value
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-element mb-4">
                  <label>
                    Email
                    <span>**</span>
                  </label>
                  <input
                    v-model="email"
                    name="email"
                    type="text"
                    value
                    placeholder="Enter Email Address"
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-element mb-4">
                  <label>
                    Contact Number (WhatsApp/ WeChat)
                    <span>**</span>
                  </label>
                  <input
                    v-model="contactNumber"
                    name="Contact_Number_(WhatsApp/_WeChat)"
                    type="text"
                    value
                    placeholder="Enter Contact Number"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-element mb-4">
                  <label>
                    Country
                    <span>**</span>
                  </label>
                  <input
                    v-model="country"
                    name="country"
                    type="text"
                    value
                    placeholder="Enter Country Name"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-element mb-4">
                  <label>
                    How should we contact you if needed?
                    <span>**</span>
                  </label>
                  <div
                    class="custom-control custom-checkbox custom-control-inline"
                  >
                    <input
                      v-model="contactMethodEmail"
                      type="checkbox"
                      id="customCheckboxInline149"
                      name="How_should_we_contact_you_if_needed?[]"
                      class="custom-control-input"
                      value="Email"
                    />
                    <label
                      class="custom-control-label"
                      for="customCheckboxInline149"
                      >Email</label
                    >
                  </div>
                  <div
                    class="custom-control custom-checkbox custom-control-inline"
                  >
                    <input
                      v-model="contactMethodWhatsapp"
                      type="checkbox"
                      id="customCheckboxInline150"
                      name="How_should_we_contact_you_if_needed?[]"
                      class="custom-control-input"
                      value="WhatsApp"
                    />
                    <label
                      class="custom-control-label"
                      for="customCheckboxInline150"
                      >WhatsApp</label
                    >
                  </div>
                  <div
                    class="custom-control custom-checkbox custom-control-inline"
                  >
                    <input
                      v-model="contactMethodWeChat"
                      type="checkbox"
                      id="customCheckboxInline151"
                      name="How_should_we_contact_you_if_needed?[]"
                      class="custom-control-input"
                      value="WeChat"
                    />
                    <label
                      class="custom-control-label"
                      for="customCheckboxInline151"
                      >WeChat</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-element mb-4">
                  <label>
                    Requirement
                    <span>**</span>
                  </label>
                  <textarea
                    v-model="requirement"
                    name="Requirement"
                    id
                    cols="30"
                    rows="10"
                    placeholder="Enter Requirement"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-element mb-4">
                  <label>
                    Approximate Budget
                    <span>**</span>
                  </label>
                  <select v-model="budget" name="Approximate_Budget">
                    <option value selected disabled>
                      Enter Approximate Budget
                    </option>
                    <option value="20$-100$">20$-100$</option>
                    <option value="100$-500$">100$-500$</option>
                    <option value="500$-1000$">500$-1000$</option>
                    <option value="1000$-10000$">1000$-10000$</option>
                    <option value="10000$ +">10000$ +</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-element mb-4">
                  <label>
                    Do you need Source files (Copyrights)?
                    <span>**</span>
                  </label>
                  <select
                    v-model="needCopyrights"
                    name="Do_you_need_Source_files_(Copyrights)?"
                  >
                    <option value selected disabled>Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-lg-12">
                <div class="form-element mb-2">
                  <label>NDA File</label>
                  <input
                    @change="uploadFiles"
                    type="file"
                    name="nda"
                    id="ndaFile"
                    value
                  />
                </div>
                <p class="text-warning mb-0">
                  ** Only doc, docx, pdf, rtf, txt, zip, rar files are allowed
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-element">
                  <div
                    class="g-recaptcha"
                    id="g-recaptcha"
                    data-sitekey="6Lea3jQaAAAAAJorgHy9ZDViav1N5qK1LBxgs3rl"
                  ></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-center">
                <button type="submit" name="button">{{ submitBtnTxt }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--   quote area end   -->
  <Footer />
</template>

<script>
import firebase from "../util/firebase";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      name: null,
      email: null,
      contactNumber: null,
      country: null,
      contactMethodEmail: false,
      contactMethodWhatsapp: false,
      contactMethodWeChat: false,
      requirement: null,
      budget: null,
      needCopyrights: null,
      ndaFile: null,
      submitBtnTxt: "Submit"
    };
  },
  methods: {
    processForm: function() {
      var self = this;
      var resp = window.grecaptcha.getResponse();

      if (resp) {
        self.submitBtnTxt = "Uploading file...";
        var storageRef = firebase.storage().ref();
        var mountainsRef = storageRef.child(
          `${Date.now()}_${self.ndaFile.name}`
        );
        mountainsRef.put(self.ndaFile).then(snapshot => {
          snapshot.ref.getDownloadURL().then(fileUrl => {
            self.submitBtnTxt = "Submitting Form...";

            firebase
              .firestore()
              .collection("quote")
              .add({
                name: this.name,
                email: this.email,
                contactNumber: this.contactNumber,
                country: this.country,
                contactMethodEmail: this.contactMethodEmail,
                contactMethodWhatsapp: this.contactMethodWhatsapp,
                contactMethodWeChat: this.contactMethodWeChat,
                requirement: this.requirement,
                budget: this.budget,
                needCopyrights: this.needCopyrights,
                ndaFile: fileUrl
              })
              .then(function() {
                alert("Message sent successfully!");

                (self.name = null),
                  (self.email = null),
                  (self.contactNumber = null),
                  (self.country = null),
                  (self.contactMethodEmail = false),
                  (self.contactMethodWhatsapp = false),
                  (self.contactMethodWeChat = false),
                  (self.requirement = null),
                  (self.budget = null),
                  (self.needCopyrights = null);
                window.grecaptcha.reset();
                self.submitBtnTxt = "Submit";
              })
              .catch(function() {
                alert("Unable to send message!");
                self.submitBtnTxt = "Submit";
              });
          });
        });
      }
    },
    uploadFiles: function(event) {
      var file = event.target.files[0];
      var filesize = (file.size / 1024 / 1024).toFixed(4); // MB
      if (filesize > 10) {
        alert("Please select a file below 10 MB");
        event.target.value = null;
        return;
      }

      this.ndaFile = file;
    }
  },
  components: {
    Header,
    Footer
  }
};
</script>
