import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import Quote from "../views/Quote.vue";
import Service from "../views/Service.vue";
import Project from "../views/Project.vue";
import Admin from "../views/Admin.vue";
import FAQ from "../views/FAQ.vue";
import TOC from "../views/TOC.vue";
import About from "../views/About.vue";

const routes = [
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/quote",
    name: "Quote",
    component: Quote
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/service",
    name: "Service",
    component: Service
  },
  {
    path: "/project",
    name: "Project",
    component: Project
  },
  {
    path: "/adminxyz123",
    name: "Admin",
    component: Admin
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ
  },
  {
    path: "/toc",
    name: "TOC",
    component: TOC
  },
  {
    path: "/about",
    name: "About",
    component: About
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
