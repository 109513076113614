<template>
  <Header />
  <!--    services details section start   -->
  <div class="service-details-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="jumbotron">
            <div class="d-flex justify-content-left">
              <button
                type="button"
                class="btn btn-dark single-team-member"
                @click="$router.go(-1)"
                style="background-color: #0b2838"
              >
                <i class="fas fa-caret-left"></i>
                Back
              </button>
            </div>
          
            <div class="row" style="margin-top: 2%; margin-bottom: 2%;">
              <div
                class="team-carousel2 common-carousel owl-carousel owl-theme owl-loaded owl-drag"
              >
                <div class="owl-stage-outer">
                  <div
                    class="owl-stage"
                    style="
                      transform: translate3d(0px, 0px, 0px);
                      transition: all 0s ease 0s;
                      width: 585px;
                    "
                  >
                    <div
                      v-bind:key="image"
                      v-for="image in project.images"
                      class="owl-item" >
                      <div class="single-team-member" style="width: 102%">
                        <div class="team-img-wrapper">
                          <img v-bind:src="image" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="owl-nav disabled">
                  <button
                    type="button"
                    role="presentation"
                    class="owl-prev disabled"
                  >
                    <i class="flaticon-left-arrow"></i>
                  </button>
                  <button
                    type="button"
                    role="presentation"
                    class="owl-next disabled"
                  >
                    <i class="flaticon-right-arrow"></i>
                  </button>
                </div>
                <div class="owl-dots disabled"></div>
              </div>
            </div>

            <h1 class="mb-4" style="color: #0b2738">
              <strong>{{ project && project.title }}</strong>
            </h1>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 col-md-8">
                  <p class="lead" style="color: #224c62">
                    {{ project.description }}
                  </p>
                </div>
                <div class="col-sm-12 col-md-4" style="color: #224c62">
                  <h4 class="mb-4 text-right">
                    <strong>Service provided</strong>
                  </h4>
                  <p
                    v-bind:key="serviceProvided"
                    v-for="serviceProvided in project.servicesProvided"
                    class="text-right"
                  >
                    {{ serviceProvided }}
                  </p>
                </div>
              </div>
            </div>
            <h4 class="mb-4" style="color: #224c62">
              <strong>Total budget : </strong>
              <small class="h1">{{ project.totalBudget }}</small>
            </h4>
            <p class="h4 mb-4" style="color: #224c62">
              Complete Project Duration - {{ project.projectDuration }}
            </p>
            <p class="h5" style="color: #224c62">
              Live product -
              <a v-bind:href="project.liveProject">{{ project.liveProject }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import firebase from "../util/firebase";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

const urlParams = new URLSearchParams(window.location.search);
const projectId = urlParams.get("project");

export default {
  data() {
    return {
      project: {},
    };
  },
  mounted() {
    var self = this;
    firebase
      .firestore()
      .collection("project")
      .doc(projectId)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          self.project = doc.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        // this.$emit('projectLoaded')
        setTimeout(function () {
          const event = new Event("projectLoaded");
          window.dispatchEvent(event);
        }, 500);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    getDataFromFirebase() {},
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
@import "/css/service-custom.css";
</style>
