<template>
  <Header />
  <!--   breadcrumb area start   -->
  <div
    class="breadcrumb-area contact"
    style="
      background-image: url(&#39;https://i.imgur.com/AtERUas.jpg&#39;);
      background-size: cover;
    "
  >
    <div class="container">
      <div class="breadcrumb-txt">
        <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-10">
            <h1>Frequently asked questions</h1>
            <ul class="breadcumb">
              <li>
                <a href="https://mechazy.com/">Home</a>
              </li>
              <li>FAQ</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="breadcrumb-area-overlay"
      style="background-color: #0a3041; opacity: 0.8"
    ></div>
  </div>
  <!--   breadcrumb area end    -->
  <div class="container" style="margin-top: 2vh; margin-bottom: 5vh">
    <div class="row">
      <div class="col">
        <h2 class="section-summary">
          Why should I hire Mechazy Engineers?
        </h2>

        <p>
          <b>Mechazy Engineering</b> is an engineering firm which is run by
          <b>qualified Engineers, Analysts, Advisors and Marketers</b> -
          covering various fields such as
        </p>
        <ul
          class="list-unstyled"
          style="list-style-type: disc; margin-left: 5vw"
        >
          <li>Mechanical Engineering</li>
          <li>Civil Engineering</li>
          <li>Electrical Engineering</li>
          <li>Electronics and Telecommunications Engineering</li>
          <li>Management and Marketing</li>
        </ul>
        <b>We are specialized in</b>
        <ul
          class="list-unstyled"
          style="list-style-type: disc; margin-left: 5vw"
        >
          <li>Industrial Machine Designing</li>
          <li>Product Designing</li>
          <li>House Designing and Planning</li>
          <li>Electrical Engineering</li>
          <li>Electronics Circuits Designing</li>
          <li>Drone Manipulators Designing</li>
        </ul>
        <h2 class="section-summary">
          How much do engineering services cost?
        </h2>
        <p>
          Since each engineering project is unique, the exact cost for a project
          cannot be defined before examining the complete project requirement.
          To obtain an exact quotation/proposal for the cost and the timeframe
          of the project, it is mandatory for the client to send us the complete
          requirement. This will help the client and also for mechazy engineers
          to manage their time accurately and provide the best service for a
          most reasonable price. Clients are welcome to contact us to get our
          support to define the project scope or the requirement in an efficient
          way.
        </p>
        <br />
        <p>
          Despite usual procedures and regulations we always try our best to
          offer custom plans for the convenience of our clients.
        </p>
        <h2 class="section-summary">
          What softwares do you use?
        </h2>
        <ul
          class="list-unstyled"
          style="list-style-type: disc; margin-left: 5vw"
        >
          <li>Solidworks</li>
          <li>AutoCAD</li>
          <li>3D max</li>
          <li>Maya</li>
          <li>Sketchup</li>
          <li>Revit Architecture</li>
          <li>Ansys</li>
          <li>Siemens NX</li>
        </ul>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer
  }
};
</script>
