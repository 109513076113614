import firebase from "firebase";

// Initialize Cloud Firestore through Firebase
firebase.initializeApp({
  apiKey: "AIzaSyALxeZnZyKNOmbeRrzz88QncmEI5zfxGUk",
  authDomain: "project-mechazy.firebaseapp.com",
  projectId: "project-mechazy",
  storageBucket: "project-mechazy.appspot.com"
});

export default firebase;
