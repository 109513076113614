<template>
  <Header />
  <!--   breadcrumb area start   -->
  <div
    class="breadcrumb-area contact"
    style="
      background-image: url(&#39;https://i.imgur.com/AtERUas.jpg&#39;);
      background-size: cover;
    "
  >
    <div class="container">
      <div class="breadcrumb-txt">
        <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-10">
            <span>Contact Us</span>
            <h1>Need information? fill form and send us</h1>
            <ul class="breadcumb">
              <li>
                <a href="https://mechazy.com/">Home</a>
              </li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="breadcrumb-area-overlay" style="background-color: #0a3041; opacity: 0.8"></div>
  </div>
  <!--   breadcrumb area end    -->

  <!--    contact form and map start   -->
  <div class="contact-form-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <form class="contact-form" @submit.prevent="processForm">
            <input type="hidden" name="_token" value="MQu5h5s6x0V8KJPZ7x2q8wigbcOdaiu1SkxD3FtC" />
            <div class="row">
              <div class="col-md-6">
                <div class="form-element">
                  <input v-model="name" name="name" type="text" placeholder="Name" required />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-element">
                  <input v-model="email" name="email" type="email" placeholder="Email" required />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-element">
                  <input
                    v-model="subject"
                    name="subject"
                    type="text"
                    placeholder="Subject"
                    required
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-element">
                  <textarea
                    v-model="message"
                    name="message"
                    id="comment"
                    cols="30"
                    rows="10"
                    placeholder="Comment"
                    required
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-element">
                  <div
                    class="g-recaptcha"
                    id="g-recaptcha"
                    data-sitekey="6Lea3jQaAAAAAJorgHy9ZDViav1N5qK1LBxgs3rl"
                  ></div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-element no-margin">
                  <input type="submit" value="Submit" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--    contact form and map end   -->
  <Footer />
</template>

<script>
import firebase from "../util/firebase";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      name: null,
      email: null,
      subject: null,
      message: null
    };
  },
  methods: {
    processForm: function() {
      var self = this;
      var resp = window.grecaptcha.getResponse();

      if (resp) {
        firebase
          .firestore()
          .collection("contact")
          .add({
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message
          })
          .then(function() {
            alert("Message sent successfully!");
            self.name = "";
            self.email = "";
            self.subject = "";
            self.message = "";
            window.grecaptcha.reset();
          })
          .catch(function() {
            alert("Unable to send message!");
          });
      } else {
        alert("Please verify captcha!");
      }
    }
  },
  components: {
    Header,
    Footer
  }
};
</script>
