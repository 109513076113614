<template>
  <Header />
  <div class="home">
    <!--   hero area start   -->
    <div
      class="hero-area hero2-carousel owl-carousel owl-theme owl-loaded owl-drag"
    >
      <div class="owl-stage-outer">
        <div
          class="owl-stage"
          style="
            transform: translate3d(0px, 0px, 0px);
            transition: all 0s ease 0s;
            width: 6720px;
          "
        >
          <div class="owl-item active" style="width: 1680px">
            <div
              class="hero-bg-2"
              style="
                background-image: url(&#39;https://i.imgur.com/bFEueoI.jpg&#39;);
                height: 607px;
              "
            >
              <div class="container">
                <div class="hero-txt">
                  <div class="row">
                    <div class="col-12">
                      <span>Mechazy Engineers</span>
                      <h1>A Step Beyond The Engineering</h1>
                      <a href="/contact" class="hero-boxed-btn">Contact Us</a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="hero-area-overlay"
                style="background-color: #0a3041; opacity: 0.6"
              ></div>
            </div>
          </div>
          <div class="owl-item" style="width: 1680px">
            <div
              class="hero-bg-2"
              style="
                background-image: url(&#39;https://i.imgur.com/9FQj6Rk.jpg&#39;);
                height: 607px;
              "
            >
              <div class="container">
                <div class="hero-txt">
                  <div class="row">
                    <div class="col-12">
                      <span>Mechazy Engineers</span>
                      <h1>A Step Beyond The Engineering</h1>
                      <a href="/contact" class="hero-boxed-btn">Contact Us</a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="hero-area-overlay"
                style="background-color: #0a3041; opacity: 0.6"
              ></div>
            </div>
          </div>
          <div class="owl-item" style="width: 1680px">
            <div
              class="hero-bg-2"
              style="
                background-image: url(&#39;https://i.imgur.com/TdvYKF4.jpg&#39;);
                height: 607px;
              "
            >
              <div class="container">
                <div class="hero-txt">
                  <div class="row">
                    <div class="col-12">
                      <span>Mechazy Engineers</span>
                      <h1>A Step Beyond The Engineering</h1>
                      <a href="/contact" class="hero-boxed-btn">Contact Us</a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="hero-area-overlay"
                style="background-color: #0a3041; opacity: 0.6"
              ></div>
            </div>
          </div>
          <div class="owl-item" style="width: 1680px">
            <div
              class="hero-bg-2"
              style="
                background-image: url(&#39;https://i.imgur.com/UPffYH9.jpg&#39;);
                height: 607px;
              "
            >
              <div class="container">
                <div class="hero-txt">
                  <div class="row">
                    <div class="col-12">
                      <span>Mechazy Engineers</span>
                      <h1>A Step Beyond The Engineering</h1>
                      <a href="/contact" class="hero-boxed-btn">Contact Us</a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="hero-area-overlay"
                style="background-color: #0a3041; opacity: 0.6"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="owl-nav disabled">
        <button type="button" role="presentation" class="owl-prev">
          <i class="flaticon-left-arrow"></i>
        </button>
        <button type="button" role="presentation" class="owl-next">
          <i class="flaticon-right-arrow"></i>
        </button>
      </div>
      <div class="owl-dots">
        <button role="button" class="owl-dot">
          <span></span>
        </button>
        <button role="button" class="owl-dot">
          <span></span>
        </button>
        <button role="button" class="owl-dot">
          <span></span>
        </button>
        <button role="button" class="owl-dot">
          <span></span>
        </button>
      </div>
    </div>
    <div class="container-fluid service-box-main-box">
      <div
        class="service-box-cham"
        style="background-color: #dce0ff; padding-left: 20px"
      >
        <div class="service-box-title" style="text-align: center;">
          <span
            class="service-text-cham"
            style="font-size: 21px; color: #224c62"
          >
            Machanical Engineering Services
            <hr class="hr-tag" />
          </span>
        </div>
        <a
          href="/service?service=mechanicalDesign"
          class="single-service-box-cham mechanical-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/tDcxltZ.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">Mechanical Design</span>
          </div>
        </a>
        <a
          href="/service?service=cadConversionMech"
          class="single-service-box-cham mechanical-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/zu4OqEJ.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">CAD Conversion</span>
          </div>
        </a>
        <a
          href="/service?service=engAnalysisNSimulation"
          class="single-service-box-cham mechanical-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/q7YZuem.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">
              Engineering Analysis & Simulation
            </span>
          </div>
        </a>
        <a
          href="/service?service=fabrication"
          class="single-service-box-cham mechanical-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/zHxDJr8.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">Fabrication</span>
          </div>
        </a>
        <a
          href="/service?service=automationNCtrlSystem"
          class="single-service-box-cham mechanical-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/6Zpiv5Q.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">
              Automation and Control Systems
            </span>
          </div>
        </a>
        <a
          href="/service?service=tdRendering"
          class="single-service-box-cham mechanical-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/cxMQDy0.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">3D Rendering</span>
          </div>
        </a>
        <a
          href="/service?service=promo"
          class="single-service-box-cham mechanical-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/9NUHfSe.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">Promo Videos</span>
          </div>
        </a>
        <a
          href="/service?service=rND"
          class="single-service-box-cham mechanical-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/0Y6qJQ9.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">Research and Development</span>
          </div>
        </a>
      </div>

      <div
        class="service-box-cham"
        style="background-color: #b9d4e5; padding-right: 20px"
      >
        <div class="service-box-title" style="text-align: center;">
          <span
            class="service-text-cham"
            style="font-size: 21px; color: #224c62"
          >
            Civil Enineering &amp; Architectural Service
            <hr class="hr-tag" />
          </span>
        </div>
        <a
          href="/service?service=cadConversion"
          class="single-service-box-cham civil-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/285FkWB.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">CAD Conversion</span>
          </div>
        </a>
        <a
          href="/service?service=houseDesign"
          class="single-service-box-cham civil-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/BKIIQvX.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">House Design</span>
          </div>
        </a>
        <a
          href="/service?service=mep"
          class="single-service-box-cham civil-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/ArEtfy3.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">MEP Design and Drafting</span>
          </div>
        </a>
        <a
          href="/service?service=hvac"
          class="single-service-box-cham civil-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/gOu07uO.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">HVAC</span>
          </div>
        </a>
        <a
          href="/service?service=architecturalIEDesign"
          class="single-service-box-cham civil-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/E715Y24.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">
              Architectural, Interior and Exterior Design
            </span>
          </div>
        </a>
        <a
          href="/service?service=landscapeDesign"
          class="single-service-box-cham civil-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/B4xRGlQ.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">Landscape Design</span>
          </div>
        </a>
        <a
          href="/service?service=bim"
          class="single-service-box-cham civil-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/ivc1dM7.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">
              BIM - Building Information Modeling
            </span>
          </div>
        </a>
        <a
          href="/service?service=tdRendering"
          class="single-service-box-cham civil-kotu"
        >
          <div class="service-box-image">
            <img src="https://i.imgur.com/cxMQDy0.png" height="40px" />
          </div>
          <div class="service-box-title">
            <span class="service-text-cham">3D Rendering</span>
          </div>
        </a>
      </div>
    </div>

    <!--   how we do section start   -->
    <div class="approach-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="approach-summary">
              <span class="section-title">Procedure</span>
              <h2 class="section-summary">
                The best procedure we have achieved to deliver you the best
                service on time!
              </h2>
              <a href="/quote" class="boxed-btn" target="_blank">
                <span> Get a Quote </span>
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <ul class="approach-lists">
              <li class="single-approach">
                <div class="approach-icon-wrapper">
                  <i class="fas fa-check-double"></i>
                </div>
                <div class="approach-text">
                  <h4>Basic Requirement</h4>
                  <p>
                    Please click the “Get a Quote” button and send us your
                    requirements.
                  </p>
                </div>
              </li>
              <li class="single-approach">
                <div class="approach-icon-wrapper">
                  <i class="fas fa-bullseye"></i>
                </div>
                <div class="approach-text">
                  <h4>Quotation</h4>
                  <p>
                    We will check your requirement and send you a quotation. We
                    will contact you if there is anything else we need to know.
                  </p>
                </div>
              </li>
              <li class="single-approach">
                <div class="approach-icon-wrapper">
                  <i class="fas fa-clipboard-list"></i>
                </div>
                <div class="approach-text">
                  <h4>Order Placing</h4>
                  <p>
                    We'll send you an email with instructions to register and
                    place the order in simple steps. You can always track the
                    order status in your “MECHAZY Dashboard”
                  </p>
                </div>
              </li>
              <li class="single-approach">
                <div class="approach-icon-wrapper">
                  <i class="fas fa-box-open"></i>
                </div>
                <div class="approach-text">
                  <h4>Initial Delivery</h4>
                  <p>
                    We will deliver you the service before the deadline. This
                    might not be the final delivery, but for sure it will be
                    what we discussed in the order requirement.
                  </p>
                </div>
              </li>
              <li class="single-approach">
                <div class="approach-icon-wrapper">
                  <i class="fas fa-recycle"></i>
                </div>
                <div class="approach-text">
                  <h4>Revisions</h4>
                  <p>
                    According to the initial requirement, You can ask us for
                    modifications on the initial delivery, until you get
                    satisfied with the best. (Or ask for more*)
                  </p>
                </div>
              </li>
              <li class="single-approach">
                <div class="approach-icon-wrapper">
                  <i class="fas fa-heart"></i>
                </div>
                <div class="approach-text">
                  <h4>Final Delivery</h4>
                  <p>We will deliver you the complete project.</p>
                </div>
              </li>
              <li class="single-approach">
                <div class="approach-icon-wrapper">
                  <i class="fas fa-angle-double-right"></i>
                </div>
                <div class="approach-text">
                  <h4>Marketing Service</h4>
                  <p>
                    You’ll be able to get your brand or product promoted with
                    the best of strategies in Graphic Designing, Web Developing
                    and Marketing, for a discounted price!
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--   how we do section end   -->

    <!--    statistics section start    -->
    <div
      class="statistics-section"
      style="
        background-image: url(&#39;https://i.imgur.com/akKega4.jpg&#39;);
        background-size: cover;
      "
      id="statisticsSection"
    >
      <div class="statistics-container">
        <div class="container">
          <div class="row no-gutters">
            <div class="col-lg-3 col-md-6">
              <div
                class="round"
                data-value="1"
                data-number="200"
                data-size="200"
                data-thickness="6"
                data-fill='{
                       "color": "#2386D0"
                       }'
              >
                <strong>200+</strong>
                <h5><i class="fas fa-laptop-code"></i> COMPLETED PROJECTS</h5>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div
                class="round"
                data-value="1"
                data-number="100"
                data-size="200"
                data-thickness="6"
                data-fill='{
                       "color": "#2386D0"
                       }'
              >
                <strong>100+</strong>
                <h5><i class="far fa-smile-beam"></i> LOYAL CLIENTS</h5>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div
                class="round"
                data-value="1"
                data-number="20"
                data-size="200"
                data-thickness="6"
                data-fill='{
                       "color": "#2386D0"
                       }'
              >
                <strong>20+</strong>
                <h5><i class="far fa-flag"></i> COUNTRIES</h5>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div
                class="round"
                data-value="1"
                data-number="200"
                data-size="200"
                data-thickness="6"
                data-fill='{
                       "color": "#2386D0"
                       }'
              >
                <strong>200+</strong>
                <h5><i class="far fa-thumbs-up"></i> POSITIVE REVIEWS</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="statistic-overlay"
        style="background-color: #0a3041; opacity: 0.9"
      ></div>
    </div>
    <!--    statistics section end    -->

    <!--   Testimonial section start    -->
    <Testimonials />
    <!--   Testimonial section end    -->

    <!--    team section start   -->
    <!-- <TeamMembers /> -->
    <!--    team section end   -->

    <!--    call to action section start    -->
    <div
      class="cta-section"
      style="
        background-image: url(&#39;https://i.imgur.com/nEInmI5.jpg&#39;);
        background-size: cover;
      "
    >
      <div class="container">
        <div class="cta-content">
          <div class="row">
            <div class="col-md-9 col-lg-7">
              <h3>Any question? Any Suggestion? Please contact us...</h3>
            </div>
            <div class="col-md-3 col-lg-5 contact-btn-wrapper">
              <a href="/contact" class="boxed-btn contact-btn">
                <span>Contact Us</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="cta-overlay"
        style="background-color: #0a3041; opacity: 0.7"
      ></div>
    </div>
    <!--    call to action section end    -->

    <!--   partner section start    -->
    <div class="partner-section">
      <div class="container top-border">
        <div class="row">
          <div class="col-md-12">
            <div
              class="partner-carousel owl-carousel owl-theme common-carousel owl-loaded owl-drag"
            >
              <div class="owl-stage-outer">
                <div
                  class="owl-stage"
                  style="
                    transform: translate3d(0px, 0px, 0px);
                    transition: all 0s ease 0s;
                    width: 684px;
                  "
                >
                  <div
                    class="owl-item active"
                    style="width: 198px; margin-right: 30px"
                  >
                    <a
                      class="single-partner-item d-block"
                      href="https://mechazy.com/#"
                      target="_blank"
                    >
                      <div class="outer-container">
                        <div class="inner-container">
                          <img src="https://i.imgur.com/YFcfA9G.png" alt />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    class="owl-item active"
                    style="width: 198px; margin-right: 30px"
                  >
                    <a
                      class="single-partner-item d-block"
                      href="https://mechazy.com/#"
                      target="_blank"
                    >
                      <div class="outer-container">
                        <div class="inner-container">
                          <img src="https://i.imgur.com/7hxR33f.png" alt />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    class="owl-item active"
                    style="width: 198px; margin-right: 30px"
                  >
                    <a
                      class="single-partner-item d-block"
                      href="https://bmcgroup.lk/"
                      target="_blank"
                    >
                      <div class="outer-container">
                        <div class="inner-container">
                          <img src="https://i.imgur.com/KAXDgIQ.png" alt />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="owl-nav disabled">
                <button
                  type="button"
                  role="presentation"
                  class="owl-prev disabled"
                >
                  <i class="flaticon-left-arrow"></i>
                </button>
                <button
                  type="button"
                  role="presentation"
                  class="owl-next disabled"
                >
                  <i class="flaticon-right-arrow"></i>
                </button>
              </div>
              <div class="owl-dots disabled"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   partner section end    -->
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Testimonials from "@/components/Testimonials.vue";
// import TeamMembers from "@/components/TeamMembers.vue";

export default {
  components: {
    Header,
    Footer,
    Testimonials,
    // TeamMembers,
  }
};
</script>

<style scoped>
.service-box-image img {
  height: 40px;
}
</style>
