<template>
  <!--   header area start   -->
  <div class="header-area header-absolute sticky-navbar">
    <div class="container">
      <div class="header-navbar">
        <div class="row">
          <div class="col-lg-2 col-6">
            <div class="logo-wrapper">
              <a href="/">
                <img src="https://i.imgur.com/FSpDITk.png" alt />
              </a>
            </div>
          </div>
          <div class="col-lg-10 col-6 text-right position-static">
            <ul class="main-menu" id="mainMenu">
              <li
                class="dropdown"
                style="text-transform: lowercase !important;"
              >
                <a class="dropdown-btn" href="/#" target="_self">
                  Mechanical Engineering
                </a>
                <ul
                  class="dropdown-lists"
                  style="width: 300px;text-transform: lowercase !important;"
                >
                  <li>
                    <a
                      href="/service?service=mechanicalDesign"
                      target="_self"
                      class="service-content-text"
                    >
                      Mechanical Design
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=cadConversionMech"
                      target="_self"
                      class="service-content-text"
                    >
                      CAD Conversion
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=engAnalysisNSimulation"
                      target="_self"
                      class="service-content-text"
                    >
                      Engineering Analysis &amp; Simulation
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=fabrication"
                      target="_self"
                      class="service-content-text"
                    >
                      Fabrication
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=automationNCtrlSystem"
                      target="_self"
                      class="service-content-text"
                    >
                      Automation and Control Systems
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=rND"
                      target="_self"
                      class="service-content-text"
                    >
                      Research and Development
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=mtdRendering"
                      target="_self"
                      class="service-content-text"
                    >
                      3D Rendering
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=promo"
                      target="_self"
                      class="service-content-text"
                    >
                      Promo Videos
                    </a>
                  </li>
                </ul>
              </li>

              <li
                class="dropdown"
                style="text-transform: lowercase !important;"
              >
                <a class="dropdown-btn" href="/#" target="_self">
                  Civil Engineering & Arcitectural
                </a>
                <ul
                  class="dropdown-lists"
                  style="width: 300px;text-transform: lowercase !important;"
                >
                  <li>
                    <a
                      href="/service?service=cadConversion"
                      target="_self"
                      class="service-content-text"
                    >
                      CAD Conversion
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=houseDesign"
                      target="_self"
                      class="service-content-text"
                    >
                      House Design
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=mepDesign"
                      target="_self"
                      class="service-content-text"
                    >
                      MEP-Design and Drafting
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=hvac"
                      target="_self"
                      class="service-content-text"
                    >
                      HVAC
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=architecturalIEDesign"
                      target="_self"
                      class="service-content-text"
                    >
                      Architectural, Interior and Exterior Design
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=landscapeDesign"
                      target="_self"
                      class="service-content-text"
                    >
                      Landscape Design
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=bim"
                      target="_self"
                      class="service-content-text"
                    >
                      BIM - Building Information Modeling
                    </a>
                  </li>
                  <li>
                    <a
                      href="/service?service=tdRendering"
                      target="_self"
                      class="service-content-text"
                    >
                      3D Rendering
                    </a>
                  </li>
                </ul>
              </li>

              <li style="text-transform: lowercase !important;">
                <a class="dropdown-btn" href="/contact" target="_self">
                  Contact Us
                </a>
              </li>

              <li
                class="dropdown"
                style="text-transform: lowercase !important;"
              >
                <a class="dropdown-btn" href="/#" target="_self">
                  Resources
                </a>
                <ul
                  class="dropdown-lists"
                  style="width: 300px;text-transform: lowercase !important;"
                >
                  <li>
                    <a
                      href="/about"
                      target="_self"
                      class="service-content-text"
                    >
                      About us
                    </a>
                  </li>
                  <li>
                    <a href="/faq" target="_self" class="service-content-text">
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a href="/toc" target="_self" class="service-content-text">
                      Terms & Conditions
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a href="/quote" class="boxed-btn">Request a Quote</a>
              </li>
            </ul>
            <div id="mobileMenu"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--   header area end   -->
</template>

<style scoped>
.service-content-text {
  text-transform: capitalize !important;
}
</style>
