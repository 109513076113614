<template>
  <Header />
  <!--   breadcrumb area start   -->
  <div
    class="breadcrumb-area contact"
    style="
      background-image: url(&#39;https://i.imgur.com/AtERUas.jpg&#39;);
      background-size: cover;
    "
  >
    <div class="container">
      <div class="breadcrumb-txt">
        <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-10">
            <h1>Terms & Conditions</h1>
            <ul class="breadcumb">
              <li>
                <a href="https://mechazy.com/">Home</a>
              </li>
              <li>Terms & Conditions</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="breadcrumb-area-overlay"
      style="background-color: #0a3041; opacity: 0.8"
    ></div>
  </div>
  <!--   breadcrumb area end    -->
  <div class="container" style="margin-top: 2vh; margin-bottom: 5vh">
    <div class="row">
      <div class="col">
        <h2 class="section-summary">
          Starting a project
        </h2>
        <ul class="list-unstyled" style="list-style-type: disc;">
          <li>
            We will be working on all projects with respect to pre-defined
            phases.
          </li>
          <li>
            Phases for a project will be defined and finalized by team mechazy,
            prior to the project starting under the client's approval.
          </li>
          <li>
            Clients must make the full payment for the corresponding Phase to
            start the specific Project/Phase.
          </li>
          <li>
            Clients must send their full requirement for the project before
            finalizing the quote. Team Mechazy have rights to request for
            additional payments upon changing the requirement.
          </li>
        </ul>
        <h2 class="section-summary">
          Payment methods
        </h2>
        <ul class="list-unstyled" style="list-style-type: disc;">
          <li>
            Clients are requested to make the corresponding payments through a
            PayPal payment gate using VISA/Mastercards or a PayPal account
            balance. Link to the payment gate will be received right after
            agreeing on a finalized quotation.
          </li>
          <li>
            When the client is not capable of using any of above mentioned
            payment methods, please let us know in the same email conversation
            of finalizing the quotation. Team Mechazy is always trying the best
            to make things easier to our clients.
          </li>
        </ul>
        <h2 class="section-summary">
          Revisions
        </h2>
        <ul class="list-unstyled" style="list-style-type: disc;">
          <li>
            Clients are eligible to request for free revisions under following
            concerns:
            <ul
              class="list-unstyled"
              style="list-style-type: disc; margin-left: 5vw"
            >
              <li>Delivered Project is incomplete</li>
              <li>
                Delivered Project is differ from the initial requirement.&nbsp;
              </li>
              <li>Client is not satisfied with the quality of the delivery.</li>
              <li>Delivered files are not accessible or damaged.</li>
            </ul>
          </li>
          <li>
            Clients are not eligible to request for free revisions due to change
            of the initial requirement agreed with the final quotation
          </li>
          <li>
            Client is requested to send the complete and finalized revision
            request through the same email conversation for the project.
          </li>
        </ul>

        <h2 class="section-summary">
          Ending a project
        </h2>
        <ul class="list-unstyled" style="list-style-type: disc;">
          <li>
            A project or phase is considered as completed, after the client is
            satisfied and approves the most recent delivery or a set of files
            shared during the project.
          </li>
          <li>
            A project or the phase is considered as completed when the client is
            not responding to the most recent delivery/message for 30 days.
          </li>
          <li>
            The client is still eligible to request revisions upto 30 days from
            the completion of the project.
          </li>
          <li>
            Since the project is ended upon the confirmation of the accuracy of
            deliveries under the satisfaction and inspection of the client, we
            are not responsible for any complaint received on the deliveries.
          </li>
        </ul>

        <h2 class="section-summary">
          Refunds
        </h2>
        <ul class="list-unstyled" style="list-style-type: disc;">
          <li>
            Mechazy will not refund any amount of the payment of completed
            projects (or phases).
          </li>
          <li>
            Refund requests received after the complete delivery of a project
            (or a phase) will be considerable only if the client has a valid
            complaint against the delivery of Mechazy. Such complaints are not
            valid due to the clients’ issues such as Patent issues, Withdrawal
            of concepts, Lack of knowledge, inability of working with
            engineering projects, limitations or rejections due to regional
            standards and conditions, etc. Valid requests will be refunded only
            upto 20%-50% of the payment.
          </li>
          <li>
            Refund requests received before the complete delivery of a project
            (or a phase) will be valid only if the client has a valid reason
            except clients’ issues such as Patent issues, Withdrawal of
            concepts, Lack of knowledge, inability of working with engineering
            projects, limitations or rejections due to regional standards or
            conditions, etc. Valid requests will be refunded only upto 20%-95%
            of the payment.
          </li>
          <li>
            Any kind of a refund will take upto 20-30 business days to transfer
            back to the client.
          </li>
          <li>
            Clients are not eligible to request for further services or files
            for refunded projects.
          </li>
          <li>
            Clients are restricted to use any mechazy creations (Concepts,
            products, materials, files, etc.) of a refunded project.
          </li>
        </ul>

        <h2 class="section-summary">
          Copyrights
        </h2>
        <ul class="list-unstyled" style="list-style-type: disc;">
          <li>
            Mechazy is responsible for respecting Non-Disclosure agreements.
          </li>
          <li>
            Copyrights will be transferred to the client by a ‘Copyright
            transfer script” after the completion of the project.
          </li>
          <li>
            Copyrights will not be transferred to the client during the project.
          </li>
          <li>
            Copyrights will not be transferred to the client for refunded
            projects.
          </li>
          <li>
            Mechazy is not responsible for consequences occurring due to actions
            taken against the warnings & conditions mentioned in the copyright
            transfer script.
          </li>
        </ul>

        <h2 class="section-summary">
          Standards
        </h2>
        <ul class="list-unstyled" style="list-style-type: disc;">
          <li>
            Mechazy engineers are always following international engineering
            standards and unbiased architectures and designs influences, unless
            the client has requested to follow a specific standard or influence.
          </li>
          <li>
            It is mandatory for the client to inform us before finalizing the
            initial requirement if the client wants us to use a specific
            standard or influence.
          </li>
        </ul>

        <h2 class="section-summary">
          Softwares
        </h2>
        <ul class="list-unstyled" style="list-style-type: disc;">
          <li>
            Mechazy engineers are allowed to use their preferred softwares and
            software editions to accomplish a target unless the client has
            requested a specific software or edition for a project.
          </li>
          <li>
            It is mandatory for the client to inform us before finalizing the
            initial requirement if the client wants us to use a specific
            software or a software edition.
          </li>
        </ul>

        <h2 class="section-summary">
          Project files
        </h2>
        <ul class="list-unstyled" style="list-style-type: disc;">
          <li>
            It is mandatory for the client to inform us before finalizing the
            initial requirement, if they need project files (Editable source
            files) after the completion of the project, because for some
            projects mechazy has to charge extra for the source files.
          </li>
          <li>
            Mechazy will not send source files to the client prior to the
            completion of the projects, but the client will receive viewable
            (non-editable) files during the process.
          </li>
          <li>
            Mechazy will store project files in our database only upto 1 year
            from the completion of the project.eceive viewable (non-editable)
            files during the process.
          </li>
        </ul>

        <h2 class="section-summary">
          Privacy
        </h2>
        <ul class="list-unstyled" style="list-style-type: disc;">
          <li>
            Mechazy will not expose any kind of critical data of the client or
            the project except for valid legal issues or complaints.
          </li>
          <li>
            Mechazy has rights to review, change or update our terms and
            conditions, privacy policy as and when required only under the
            approval of Mechazy directors.
          </li>
        </ul>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer
  }
};
</script>
