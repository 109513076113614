const serviceMap = {
  mechanicalDesign: {
    title: "Mechanical Design",
    descriptions: [
      "Mechanical design services are offered across various industries in product designing and machine designing."
    ],
    subTitles: [
      "Conceptual design",
      "Calculation",
      "3D Modeling",
      "Simulation",
      "Technical Drawing & Documentation",
      "Rendering & Animation"
    ]
  },
  cadConversionMech: {
    title: "CAD Conversion (Mechanical)",
    descriptions: [
      "Mechazy Engineering solutions offers CAD conversion services. Hand-drawn sketches, PDFs, scan-based drawings having been converting to the CAD. "
    ],
    subTitles: [
      "Rough Sketch to CAD",
      "2D to 3D",
      "3D to 2D",
      "2D CAD drawings (E.g. SolidWorks) to AutoCAD drawings",
      "2D drawings from conceptual sketches and images"
    ]
  },
  engAnalysisNSimulation: {
    title: "Engineering Analysis & Simulation",
    descriptions: [
      "Simulation is a critical tool to test on the product design with several benefits such as reducing product failures, reducing manufacturing cost, and reducing product development time. Finite Element Analysis (FEA), Computational Fluid Dynamic (CFD) and manual calculation will be provided to detect problems and increase the efficiency of the product before going through the manufacturing procedure."
    ],
    subTitles: [
      "Static and dynamic simulation",
      "Structural analysis and simulation",
      "Mechanism dynamic analysis and simulation",
      "Stress, Fracture Analysis, and Fatigue Analysis",
      "Fluid simulation",
      "Thermal Simulation",
    ]
  },
  fabrication: {
    title: "Fabrication",
    descriptions: [""],
    subTitles: [
      "3D Print ready file",
      "CNC G codes",
      "Laser Cutting (DXF) ready file",
      "Manufacturing procedure"
    ]
  },
  automationNCtrlSystem: {
    title: "Automation and Control Systems ",
    descriptions: [
      "Industrial automation and control system services are provided detailed design with simulation of industrial automation system"
    ],
    subTitles: [
      "Programmable Logic controller (PLC) Programming",
      "Process optimization"
    ]
  },
  rND: {
    title: "Research and Development",
    descriptions: [
      "Continuously evolving for new projects related mechanical engineering, there will be a number of complex technical problems which are needed to solve. Due to this kind of problems we are providing scientific research services which help to fill the gap between product development and expectation for",
      "1) New market product/ Machine design concepts",
      "2) Material based research",
      "Process,"
    ],
    subTitles: [
      "Gathering informations from customer",
      "Feasibility study",
      "Scientific and engineering literature review",
      "Modeling design"
    ]
  },
  promo: {
    title: "Promo Videos",
    descriptions: ["There are different types of Promo videos"],
    subTitles: ["Marketing Video", "Technical Presentation"]
  },
  tdRendering: {
    title: "3D Rendering",
    descriptions: ["Our Civil Engineering 3D Rendering services"],
    subTitles: [
      "3D Architectural Modeling and Rendering",
      "3D Walkthrough and animation",
      "3D Floor Plans",
      "3D Interior and Exterior"
    ]
  },
  mtdRendering: {
    title: "3D Rendering",
    descriptions: ["Our Mechanical Engineering 3D Rendering services"],
    subTitles: [
      "3D Architectural Modeling and Rendering",
      "3D Walkthrough and animation",
      "3D Floor Plans",
      "3D Interior and Exterior"
    ]
  },
  cadConversion: {
    title: "CAD Conversion",
    descriptions: ["Our CAD Conversion services"],
    subTitles: [
      "Rough Sketch - CAD",
      "Paper - CAD",
      "PDF - CAD",
      "3D - 2D Conversion"
    ]
  },
  houseDesign: {
    title: "House Design",
    descriptions: ["Our House Design services"],
    subTitles: [
      "Modern House Design",
      "European House Design",
      "Farm horse",
      "Dutch",
      "Cottages",
      "Cabana",
      "Any custom concept"
    ]
  },
  mepDesign: {
    title: "MEP Design and Drafting",
    descriptions: ["Our MEP Design and Drafting services"],
    subTitles: [
      "Heating and Cooling Load Calculation, and System design",
      "Heating and Cooling System Equipment Selection",
      "Duct Layout Design",
      "Wiring Diagram",
      "Electrical Single Line Diagram",
      "Electrical Distribution Panel Selection",
      "Design and Drafting Domestic & Industrial water Plumbing System"
    ]
  },
  hvac: {
    title: "HVAC",
    descriptions: ["Our HVAC services"],
    subTitles: ["Heating System Design", "Ventilation", "Cooling System design"]
  },
  architecturalIEDesign: {
    title: "Architectural, interior and exterior design",
    descriptions: ["Our Architectural, interior and exterior design services"],
    subTitles: [
      "3D Modeling and Rendering",
      "2D Drafting of Floor Plans",
      "Planning and Design",
      "Virtual Staging"
    ]
  },
  landscapeDesign: {
    title: "Landscape Design",
    descriptions: ["Our Landscape Design services"],
    subTitles: [
      "2D Drawing and Site Plan",
      "3D Modeling and Rendering for Landscaping"
    ]
  },
  bim: {
    title: "BIM - Building Information Modeling",
    descriptions: ["Our BIM (Building Information Modeling) services"],
    subTitles: ["BIM 3D Modeling"]
  }
};

export default serviceMap;
