<template>
  <nav v-if="signedIn" class="navbar navbar-dark bg-dark fixed-top">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="https://i.imgur.com/FSpDITk.png" alt height="24" />
      </a>
      <form class="my-2 my-lg-0">
        <button
          class="btn btn-outline-success my-2 my-sm-0"
          type="submit"
          v-on:click="logout"
        >
          Logout
        </button>
      </form>
    </div>
  </nav>
  <div v-if="!signedIn" class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">
            <h5 class="card-title text-center">Sign In</h5>
            <form class="form-signin">
              <div class="form-label-group">
                <input
                  type="email"
                  id="inputEmail"
                  class="form-control"
                  placeholder="Email address"
                  v-model="email"
                  required
                  autofocus
                />
                <label for="inputEmail">Email address</label>
              </div>

              <div class="form-label-group">
                <input
                  type="password"
                  id="inputPassword"
                  class="form-control"
                  placeholder="Password"
                  v-model="password"
                  required
                />
                <label for="inputPassword">Password</label>
              </div>

              <button
                class="btn btn-lg btn-primary btn-block text-uppercase"
                v-on:click="login"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Admin UI -->
  <AdminComp v-if="signedIn" />
</template>

<script>
import firebase from "../util/firebase";
import AdminComp from "@/components/AdminComp.vue";

export default {
  data() {
    return {
      signedIn: false,
      email: "",
      password: ""
    };
  },
  mounted() {
    var self = this;

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        self.signedIn = true;
      } else {
        self.signedIn = false;
        // No user is signed in.
      }
    });
  },
  components: {
    AdminComp
  },
  methods: {
    login(e) {
      e.preventDefault();
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {})
        .catch(error => {
          var errorCode = error.code;
          var errorMessage = error.message;
          alert("error occured", errorCode, errorMessage);
        });
    },
    logout() {
      var self = this;
      firebase
        .auth()
        .signOut()
        .then(() => {
          self.signedIn = false;
        })
        .catch(error => {
          // An error happened.
          alert("Error occured!", error);
        });
    }
  }
};
</script>

<style scoped>
.admin-container {
  margin-top: 8em;
}
</style>
