<template>
  <div class="container admin-container">
    <!-- Just an image -->

    <h1></h1>

    <!--  Tabs -->
    <div id="tabs" class="container">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a href="#q-req" class="nav-link active" data-toggle="tab"
            >Quote Requests</a
          >
        </li>
        <li class="nav-item">
          <a href="#contact-us" class="nav-link" data-toggle="tab"
            >Contact Us Requests</a
          >
        </li>
        <li class="nav-item">
          <a href="#projects" class="nav-link" data-toggle="tab">Projects</a>
        </li>
        <li class="nav-item">
          <a href="#create-proj" class="nav-link" data-toggle="tab"
            >Create Project</a
          >
        </li>
      </ul>
    </div>

    <div class="tab-content clearfix">
      <div class="tab-content clearfix">
        <div class="tab-pane active" id="q-req">
          <ul v-if="quotes.length > 0" class="list-group">
            <li
              v-bind:key="quote.id"
              v-for="quote in quotes"
              class="list-group-item"
            >
              Name : {{ quote.name }}
              <button
                v-if="expanded != quote.id"
                type="button"
                v-on:click="expanded = quote.id"
                class="btn btn-success list-action"
              >
                Expand
              </button>
              <button
                v-if="expanded == quote.id"
                type="button"
                v-on:click="expanded = ''"
                class="btn btn-success list-action"
              >
                Collapse
              </button>
              <button
                type="button"
                v-on:click="deleteQuote(quote.id)"
                class="btn btn-danger list-action"
              >
                Delete
              </button>
              <ul v-if="expanded == quote.id">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Field</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-bind:key="field" v-for="field in Object.keys(quote)">
                      <td>{{ field }}</td>
                      <td v-if="field != 'ndaFile'">{{ quote[field] }}</td>
                      <td v-if="field == 'ndaFile'">
                        <a
                          v-if="field == 'ndaFile'"
                          target="_blank"
                          v-bind:href="quote[field]"
                        >
                          {{ quote[field] }}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ul>
            </li>
          </ul>
        </div>

        <div class="tab-pane" id="contact-us">
          <ul v-if="contacts.length > 0" class="list-group">
            <li
              v-bind:key="contact.id"
              v-for="contact in contacts"
              class="list-group-item"
            >
              Name : {{ contact.name }}
              <button
                v-if="expanded != contact.id"
                type="button"
                v-on:click="expanded = contact.id"
                class="btn btn-success list-action list-action"
              >
                Expand
              </button>
              <button
                v-if="expanded == contact.id"
                type="button"
                v-on:click="expanded = ''"
                class="btn btn-success list-action list-action"
              >
                Collapse
              </button>
              <button
                type="button"
                v-on:click="deleteContact(contact.id)"
                class="btn btn-danger list-action"
              >
                Delete
              </button>
              <ul v-if="expanded == contact.id">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Field</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-bind:key="field"
                      v-for="field in Object.keys(contact)"
                    >
                      <td>{{ field }}</td>
                      <td>{{ contact[field] }}</td>
                    </tr>
                  </tbody>
                </table>
              </ul>
            </li>
          </ul>
        </div>

        <div class="tab-pane" id="projects">
          <ul v-if="projects.length > 0" class="list-group">
            <li
              v-bind:key="project.id"
              v-for="project in projects"
              class="list-group-item"
            >
              {{ project.title }}
              <button
                v-if="expanded != project.id"
                type="button"
                v-on:click="expanded = project.id"
                class="btn btn-success list-action"
              >
                Expand
              </button>
              <button
                v-if="expanded == project.id"
                type="button"
                v-on:click="expanded = ''"
                class="btn btn-success list-action list-action"
              >
                Collapse
              </button>
              <button
                type="button"
                v-on:click="deleteProject(project.id)"
                class="btn btn-danger list-action"
              >
                Delete
              </button>
              <ul v-if="expanded == project.id">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Field</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-bind:key="field"
                      v-for="field in Object.keys(project)"
                    >
                      <td>{{ field }}</td>
                      <td>{{ project[field] }}</td>
                    </tr>
                  </tbody>
                </table>
              </ul>
            </li>
          </ul>
        </div>

        <div class="tab-pane" id="create-proj">
          <div class="project-form container">
            <form>
              <div class="form-group">
                <label for="project-title">Project Title</label>
                <input
                  class="form-control"
                  id="project-title"
                  v-model="project.title"
                />
              </div>

              <div class="form-group">
                <label for="total-budget">Total Budget</label>
                <input
                  class="form-control"
                  id="total-budget"
                  v-model="project.totalBudget"
                />
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                  class="form-control"
                  id="description"
                  v-model="project.description"
                />
              </div>

              <div class="form-group">
                <label for="project-duration">Project Duration</label>
                <input
                  class="form-control"
                  id="project-duration"
                  v-model="project.projectDuration"
                />
              </div>

              <div class="form-group">
                <label for="project-duration">Client's Country</label>
                <input
                  class="form-control"
                  id="project-duration"
                  v-model="project.clientCountry"
                />
              </div>

              <div class="form-group">
                <label for="live-project">Live Project</label>
                <input
                  class="form-control"
                  id="live-project"
                  v-model="project.liveProject"
                />
              </div>

              <div class="form-group">
                <label for="services">Services</label>
                <select
                  class="form-control"
                  id="services"
                  v-model="project.services"
                  multiple
                >
                  <option>mechanicalDesign</option>
                  <option>cadConversionMech</option>
                  <option>engAnalysisNSimulation</option>
                  <option>fabrication</option>
                  <option>automationNCtrlSystem</option>
                  <option>rND</option>
                  <option>promo</option>
                  <option>tdRendering</option>
                  <option>mtdRendering</option>
                  <option>cadConversion</option>
                  <option>houseDesign</option>
                  <option>mepDesign</option>
                  <option>hvac</option>
                  <option>architecturalIEDesign</option>
                  <option>landscapeDesign</option>
                  <option>bim</option>
                </select>
              </div>

              <div class="form-group">
                <label for="images">Images (comma seperated)</label>
                <textarea
                  class="form-control"
                  id="images"
                  v-model="project.images"
                />
              </div>

              <div class="form-group">
                <label for="service-provided"
                  >Service Provided (comma seperated)</label
                >
                <textarea
                  class="form-control"
                  id="services-provided"
                  v-model="project.servicesProvided"
                />
              </div>

              <button v-on:click="createProject" class="btn btn-primary">
                Add Project
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../util/firebase";

export default {
  data() {
    return {
      project: {},
      expanded: "",
      quotes: [],
      contacts: [],
      projects: []
    };
  },
  mounted() {
    var self = this;
    // fetch quotes and contact requests
    firebase
      .firestore()
      .collection("quote")
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.quotes.push({ id: doc.id, ...doc.data() });
        });
      });

    firebase
      .firestore()
      .collection("contact")
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.contacts.push({ id: doc.id, ...doc.data() });
        });
      });

    firebase
      .firestore()
      .collection("project")
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.projects.push({ id: doc.id, ...doc.data() });
        });
      });
  },
  methods: {
    createProject(event) {
      var self = this;
      event.preventDefault();

      let projectDoc = {
        title: this.project.title || "",
        description: this.project.description || "",
        totalBudget: this.project.totalBudget || "",
        projectDuration: this.project.projectDuration || "",
        liveProject: this.project.liveProject || "",
        images: (this.project.images && this.project.images.split(",")) || [],
        services: this.project.services || [],
        clientCountry: this.project.clientCountry || "",
        servicesProvided:
          (this.project.servicesProvided &&
            this.project.servicesProvided.split(",")) ||
          []
      };

      firebase
        .firestore()
        .collection("project")
        .add(projectDoc)
        .then(doc => {
          alert("Project added successfully!");
          self.projects.push({ id: doc.id, ...projectDoc });
          self.project = {};
        });
    },
    deleteQuote(id) {
      var self = this;
      firebase
        .firestore()
        .collection("quote")
        .doc(id)
        .delete()
        .then(() => {
          self.quote =
            self.quote &&
            self.quote.filter(item => {
              return item.id != id;
            });
          alert("Quote deleted successfully!");
          self.quotes = self.quotes.filter(quote => {
            return quote.id != id;
          });
        });
    },
    deleteContact(id) {
      var self = this;
      firebase
        .firestore()
        .collection("contact")
        .doc(id)
        .delete()
        .then(() => {
          self.contacts =
            self.contacts &&
            self.contacts.filter(item => {
              return item.id != id;
            });
          alert("Contact deleted successfully!");
          self.contacts = self.contacts.filter(contact => {
            return contact.id != id;
          });
        });
    },
    deleteProject(id) {
      var self = this;
      firebase
        .firestore()
        .collection("project")
        .doc(id)
        .delete()
        .then(() => {
          self.projects =
            self.projects &&
            self.projects.filter(item => {
              return item.id != id;
            });
          alert("Project deleted successfully!");
          self.projects = self.projects.filter(project => {
            return project.id != id;
          });
        });
    }
  }
};
</script>

<style scoped>
.list-action {
  float: right;
  margin-right: 8px;
}
.project-form {
  margin-top: 15px;
}
</style>
