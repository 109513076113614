<template>
  <!--    footer section start   -->
  <footer class="footer-section">
    <div class="container">
      <div class="top-footer-section">
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <div class="footer-logo-wrapper">
              <a href="https://mechazy.com/">
                <img src="https://i.imgur.com/FSpDITk.png" alt />
              </a>
            </div>
            <p class="footer-txt">
              We are a team of experienced engineers, specialized in Mechanical
              and Civil Engineering who are well dedicated to deliver the best
              services to our clients beyond their expectations.
            </p>
          </div>

          <div class="col-lg-2 col-md-3">
            <h4>Machanical Engineering Services</h4>
            <ul class="footer-links">
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=9">
                  Mechanical Design
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=10">
                  CAD Conversion
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=11">
                  Engineering Analysis &amp; Simulation
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=12">
                  Fabrication
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=13">
                  Automation and Control Systems
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=14">
                  3D Rendering
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=14">
                  Promo Videos
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=14">
                  Research and Development
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-3">
            <h4>Civil Enineering &amp; Architectural Service</h4>
            <ul class="footer-links">
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=9">
                  CAD Conversion
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=10">
                  House Design
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=11">
                  MEP Design and Drafting
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=12">
                  HVAC
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=13">
                  Architectural, Interior and Exterior Design
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=14">
                  Landscape Design
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=13">
                  BIM - Building Information Modeling
                </a>
              </li>
              <li>
                <a href="https://megasoft.biz/plusagency/services?category=14">
                  3D Rendering
                </a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-5">
            <h4>Contact Us</h4>
            <div class="footer-contact-info">
              <ul>
                <li>
                  <i class="far fa-envelope"></i>
                  <span>mechazy@gmai.com</span>
                </li>
              </ul>
            </div>
            <div class="footer-social-links">
              <a target="_blank" href="https://www.facebook.com/mechazy">
                <i class="fab fa-facebook-f iconpicker-component"></i>
              </a>
              <a target="_blank" href="https://www.twitter.com/">
                <i class="fab fa-twitter"></i>
              </a>
              <a target="_blank" href="https://www.linkedin.com/">
                <i class="fab fa-linkedin-in"></i>
              </a>
              <a target="_blank" href="https://www.instagram.com/">
                <i class="fab fa-instagram iconpicker-component"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-section">
        <div class="row">
          <div class="col-sm-12 text-center">
            Copyright © 2020. All rights reserved Mechazy
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!--    footer section end   -->
  <!-- preloader section start -->
  <div class="loader-container">
    <span class="loader">
      <span class="loader-inner"></span>
    </span>
  </div>
  <!-- preloader section end -->

  <!-- back to top area start -->
  <div class="back-to-top show">
    <i class="fas fa-chevron-up"></i>
  </div>
  <!-- back to top area end -->
</template>
