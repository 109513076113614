<template>
  <router-view />
</template>

<script></script>

<style>
@import "/css/bootstrap.min.css";
@import "/css/plugin.min.css";
@import "/css/style.css";
@import "/css/responsive.css";
@import "/css/base-color.css";
@import "/css/custom.css";
</style>
