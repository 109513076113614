<template>
  <Header />
  <!--   breadcrumb area start   -->
  <div
    class="breadcrumb-area contact"
    style="
      background-image: url(&#39;https://i.imgur.com/AtERUas.jpg&#39;);
      background-size: cover;
    "
  >
    <div class="container">
      <div class="breadcrumb-txt">
        <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-10">
            <h1>About us</h1>
            <ul class="breadcumb">
              <li>
                <a href="https://mechazy.com/">Home</a>
              </li>
              <li>About us</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="breadcrumb-area-overlay"
      style="background-color: #0a3041; opacity: 0.8"
    ></div>
  </div>
  <!--   breadcrumb area end    -->
  <div class="container" style="margin-top: 2vh; margin-bottom: 5vh">
    <div class="row">
      <div class="col">
        <p>
          Welcome to Mechazy, a best Engineering service provider for an
          affordable price. We are a team of experienced engineers, specialized
          in Mechanical and Civil Engineering. We always maintain the customer
          satisfaction and the quality of the service by suggesting our opinions
          and better solutions for our clients.&nbsp;
        </p>
        <br />
        <p>
          Punctuality is our best practice. We respect our each client equally
          regardless of the difficulty of the project. We believe that honesty
          is the key for success for both parties. You will experience the best
          and most friendly customer service and communication among all the
          other engineering service providers.&nbsp;
        </p>
        <br />
        <h3><strong>Our Vision</strong></h3>
        <h4>Engineering for a better future!</h4>
        <br />
        <h3><strong>Our Mission</strong></h3>
        <h6>
          To provide friendly, responsive, safe, and trusted solutions to our
          clients with the best of our expertise, for an affordable price in the
          Mechanical and Civil engineering industry.&nbsp;
        </h6>
        <br />
        <h3><strong>Team</strong></h3>
        <h6>Advisors (PHD)</h6>
        <h6>Chartered Engineer</h6>
        <h6>Mechanical Engineers</h6>
        <h6>Civil Engineers</h6>
        <h6>Electrical Engineers</h6>
        <h6>Architects</h6>
        <h6>Technical officers</h6>
        <h6>Draftsmen</h6>
        <h6>Quantity surveyors</h6>
        <h6>Experienced Technicians</h6>
        <h6>Project Managers</h6>
        <h6>Graphic designers</h6>
        <h6>Video Editors</h6>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer
  }
};
</script>
